
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getProductionUrl } from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  components: {},
  setup() {
    let checkpw = ref<boolean>(false);
    let errormsg = ref<boolean>(false);
    const urls = ref([]);
    const newUrls = ref([]);
    let searchquery = ref("");
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Production Script", [""]);
      Swal.fire({
        title: "Enter your password",
        input: "password",
        inputLabel: "Password",
        inputPlaceholder: "Enter your password",
        inputAttributes: {
          maxlength: 10,
          autocapitalize: "off",
          autocorrect: "off",
        },
      }).then((result) => {
        console.log(result);
        if (result.value == "smartps") {
          checkpw.value = result.isConfirmed;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          errormsg.value = true;
        }
      });
      const result = await getProductionUrl();
      urls.value = result.data.data;
      console.log(result);
      newUrls.value = result.data.data;
    });
    //search fuction for production script
    const filtereddata = () => {
      const valuee = newUrls.value.filter(
        (item: any) =>
          item.toLowerCase().indexOf(searchquery.value.toLowerCase()) !== -1
      );
      console.log("value we are looking", valuee);
      urls.value = valuee;
    };
    return {
      urls,
      checkpw,
      errormsg,
      newUrls,
      searchquery,
      filtereddata,
    };
  },
};
